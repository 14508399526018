import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/38105eb3-587f-4c23-ae29-2452f5d7c3d8/components/global/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/38105eb3-587f-4c23-ae29-2452f5d7c3d8/components/global/ProgressBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/38105eb3-587f-4c23-ae29-2452f5d7c3d8/components/global/RealUserMonitoring.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/38105eb3-587f-4c23-ae29-2452f5d7c3d8/components/global/WebVitals.tsx");
;
import(/* webpackMode: "eager" */ "/build/38105eb3-587f-4c23-ae29-2452f5d7c3d8/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Marianne/static/Marianne-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Bold.woff2\",\"weight\":\"700\"}],\"fallback\":[\"arial\"]}],\"variableName\":\"marianne\"}");
;
import(/* webpackMode: "eager" */ "/build/38105eb3-587f-4c23-ae29-2452f5d7c3d8/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/build/38105eb3-587f-4c23-ae29-2452f5d7c3d8/styles/typography.css");
