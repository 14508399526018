import(/* webpackMode: "eager" */ "/build/5e21aac1-952b-42bc-bf15-cb07a3ab82b1/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/build/5e21aac1-952b-42bc-bf15-cb07a3ab82b1/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/build/5e21aac1-952b-42bc-bf15-cb07a3ab82b1/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/build/5e21aac1-952b-42bc-bf15-cb07a3ab82b1/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/build/5e21aac1-952b-42bc-bf15-cb07a3ab82b1/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/build/5e21aac1-952b-42bc-bf15-cb07a3ab82b1/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/build/5e21aac1-952b-42bc-bf15-cb07a3ab82b1/node_modules/next/dist/lib/metadata/metadata-boundary.js");
